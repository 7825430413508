export const useLocalRef = <T>(key: string, initValue: T) => {
  const state = ref<T>()

  onMounted(() => {
    if (typeof localStorage === 'undefined') {
      return
    }
    const ItemInLc = localStorage.getItem(key)
    if (!ItemInLc) {
      return (state.value = initValue)
    }
    state.value = JSON.parse(ItemInLc)
  })

  return computed({
    get(): T {
      return state.value
    },
    set(newValue: T) {
      localStorage.setItem(key, JSON.stringify(newValue))
      state.value = newValue
    }
  })
}
